/**
 * Action for setting registry versions (state.versions.registryVersions)
 * @param {Object} payload
 * @param {Object} payload.data new registry versions
 * @returns null
 */
export const SET_REGISTRY_VERSIONS = (payload) => ({
  type: "SET_REGISTRY_VERSIONS",
  payload,
});

/**
 * Action for setting registry versions (state.versions.registryVersions)
 * @param {Object} payload
 * @param {Object} payload.data new registry version
 * @param {Object} payload.registryVersion registryVersion id
 * @returns null
 */
export const SET_REGISTRY_VERSION = (payload) => ({
  type: "SET_REGISTRY_VERSION",
  payload,
});

/**
 * Action for setting published version (state.versions.registryVersions.publishedVersion)
 * @param {Object} payload
 * @param {String} payload.version new version
 * @returns null
 */
export const SET_PUBLISHED_VERSION = (payload) => ({
  type: "SET_PUBLISHED_VERSION",
  payload,
});

/**
 * Action for setting published version (state.versions.registryVersions.publishedDraftVersion)
 * @param {Object} payload
 * @param {String} payload.version new version
 * @returns null
 */
export const SET_PUBLISHED_DRAFT_VERSION = (payload) => ({
  type: "SET_PUBLISHED_DRAFT_VERSION",
  payload,
});

/**
 * Action for setting version changes (state.versions.versionChanges[version])
 * @param {Object} payload
 * @param {String} payload.root tree root e.g. "products"
 * @param {String} payload.version version to use
 * @param {String} payload.versionChanges new versionChanges
 * @returns null
 */
export const SET_VERSION_CHANGES = (payload) => ({
  type: "SET_VERSION_CHANGES",
  payload,
});

/**
 * Action for setting selectedVersion (state.versions.selectedVersion)
 * @param {Object} payload
 * @param {Number} payload.version version number
 * @returns null
 */
export const SET_VERSION = (payload) => ({
  type: "SET_VERSION",
  payload,
});
