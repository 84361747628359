import React, { useCallback, useState, useEffect } from "react";
import update from "immutability-helper";
import ReactJson from "react-json-view";
import Big from "big.js";

import { VscListTree, VscJson } from "react-icons/vsc";

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { MdClose, MdSave } from "react-icons/md";

import {
  byString,
  handleObjectChange,
  onTextChange,
} from "../helpers/functions";
import { requestNodes } from "../helpers/requests";
import { request } from "../helpers/api";
import { getChildren } from "../helpers/getChildren";

import EditTreeControls from "../components/EditTreeControls";
import ItemsView from "../components/ItemsView";
import PathsView from "../components/PathsView";
import AsyncAutocomplete from "../components/AsyncAutocomplete";
import ProductTable from "../components/ProductTable";
import { RowText } from "../components/StyledComponents";
import { toast } from "react-toastify";

import { connect } from "react-redux";
import { SET_SETTING } from "../actions/CommonActions";
import { SET_CHILDREN, UPDATE_NODE } from "../actions/TreeActions";
import { SET_NODES, SET_PATHS } from "../actions/ItemsActions";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

const editedColor = "#ebc96c";
// const removedColor = "#c79999";
// const addedColor = "#b9d4b4";

const editedStyle = { backgroundColor: editedColor, borderRadius: "4px" };

// backgroundColor: item?.changedValues?.find(
//   (x) =>
//     x.id === "nodeTextName" &&
//     x.changedValue === item?.nodeText?.name
// )
//   ? "#ebc96c"
//   : undefined,

const isFilled = (val) => {
  if (typeof val === "boolean") {
    return true;
  } else if (Array.isArray(val)) {
    if (val.length > 0) return true;
    else return false;
  } else {
    return val !== "" && val !== undefined;
  }
};

function getPickerMenuItems(t, options, key) {
  if (options && Array.isArray(options)) {
    return options.map((option, index) => {
      const _option = t(option);
      return (
        <MenuItem
          /*className={classes.label}*/ key={key + _option}
          value={_option}
        >
          {_option}
        </MenuItem>
      );
    });
  } else return [];
}

const getEquationInputValue = (
  value,
  values,
  key,
  valueFromRegistryVersion,
  registryVersion,
  registryVersions
) => {
  if (key === "this") {
    return value;
  } else {
    return getInputValue(
      values,
      key,
      valueFromRegistryVersion,
      registryVersion,
      registryVersions
    );
  }
};
const getInputValue = (
  values,
  key,
  valueFromRegistryVersion,
  registryVersion,
  registryVersions
) => {
  if (valueFromRegistryVersion) {
    return byString(registryVersions?.versions[registryVersion], key) || "";
  } else {
    return byString(values, key) || "";
  }
};

function FormComponent({
  t,
  disabled,
  registryVersion,
  treeRoot,
  productsMap,
  nodesMap,
  wholeSalers,
  input,
  values,
  errors,
  values2,
  handleChange,
  registryVersions,
}) {
  const label = t(input.label) + (input.required ? "*" : "");

  let value = getInputValue(
    values,
    input.key,
    input.valueFromRegistryVersion,
    registryVersion,
    registryVersions
  );

  if (input.equation) {
    try {
      if (input.equation[1]?.operation === "*") {
        let value1 = getEquationInputValue(
          value,
          values,
          input.equation[0]?.value,
          input.equation[0]?.valueFromRegistryVersion,
          registryVersion,
          registryVersions
        );
        if (value1) value1 = new Big(value1);
        let value2 = getEquationInputValue(
          value,
          values,
          input.equation[2]?.value,
          input.equation[2]?.valueFromRegistryVersion,
          registryVersion,
          registryVersions
        );
        if (value2) value2 = new Big(value2);
        value = value1 && value2 ? value1.times(value2) : 0;
      } else {
        throw new Error(
          "Missing operation: " +
            JSON.stringify(input.equation[1]) +
            " for equation:" +
            JSON.stringify(input.equation)
        );
      }
    } catch (error) {
      console.error(error);
      value = 0;
    }
  }
  if (input.type === "textField") {
    return (
      <TextField
        label={label}
        error={errors[input.key] ? true : false}
        value={value}
        sx={
          values2 && input.key && (byString(values2, input.key) || "") !== value
            ? editedStyle
            : null
        }
        onChange={
          input.key && !disabled && !input.disabled
            ? (ev) => handleChange(input.key, input.numeric)(ev.target.value)
            : null
        }
        fullWidth
      />
    );
  } else if (input.type === "picker") {
    return (
      <FormControl variant="outlined" fullWidth>
        <InputLabel>{label}</InputLabel>
        <Select
          fullWidth
          value={value.toString()}
          onChange={
            input.key && !disabled && !input.disabled
              ? (ev) => handleChange(input.key)(ev.target.value)
              : null
          }
          input={<OutlinedInput label={label} name={label} />}
        >
          {getPickerMenuItems(t, input.options, input.key)}
        </Select>
      </FormControl>
    );
  } else if (input.type === "text") return <RowText title={input.value} />;
  else if (input.type === "autoComplete") {
    return (
      <AsyncAutocomplete
        t={t}
        disabled={disabled || input.disabled}
        label={label}
        registryVersion={registryVersion}
        treeRoot={treeRoot}
        productsMap={productsMap}
        nodesMap={nodesMap}
        value={value}
        idProp={input.idProp}
        searchParams={input.searchParams}
        onSelect={
          input.key && !input.disabled
            ? (item) =>
                handleChange(
                  input.key,
                  false,
                  input.idProp,
                  input.multi,
                  false,
                  input.format,
                  input.fn
                )(item)
            : null
        }
      />
    );
  } else if (input.type === "suppliers") {
    if (value) {
      return (
        <ProductTable
          editable={!(disabled || input.disabled)}
          wholeSalers={wholeSalers}
          data={value}
          registryVersion={registryVersion}
          onChange={
            !(disabled || input.disabled)
              ? (key, numeric, value) =>
                  handleChange(
                    input.key + key,
                    numeric,
                    input.idProp,
                    input.multi,
                    false,
                    input.format,
                    input.fn
                  )(value)
              : null
          }
        />
      );
    } else return null;
  } else return <div>Missing form input type</div>;
}

function FormView(props) {
  const _setPathsExpanded = () => {
    props.SET_SETTING({
      prop: "pathsExpanded",
      value: !props.userSettings?.pathsExpanded,
    });
  };

  const _setJSONExpanded = () => {
    props.SET_SETTING({
      prop: "JSONExpanded",
      value: !props.userSettings?.JSONExpanded,
    });
  };

  return props.form.data.map((formBox, index) => {
    if (formBox) {
      return (
        <React.Fragment key={"FormBox" + index}>
          {formBox.title && (
            <Grid
              item
              xs={12}
              sx={{ paddingTop: "16px", paddingBottom: "16px" }}
            >
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h6">{props.t(formBox.title)}</Typography>

                {props.onClose && index === 0 && (
                  <IconButton onClick={props.onClose}>
                    <MdClose />
                  </IconButton>
                )}
              </Stack>
            </Grid>
          )}

          {props.values && (
            <>
              <Grid item xs={12}>
                <Stack direction="row" spacing={2}>
                  {!props.disablePaths && (
                    <Stack direction="row" alignItems="center">
                      <Switch
                        checked={props.userSettings?.pathsExpanded ?? false}
                        onChange={_setPathsExpanded}
                      />
                      <Typography
                        variant="button"
                        sx={{ paddingRight: "6px", fontWeight: "bold" }}
                      >
                        {props.t("paths")}
                      </Typography>
                      <VscListTree size={22} />
                    </Stack>
                  )}
                  {!props.disableJSON && (
                    <Stack direction="row" alignItems="center">
                      <Switch
                        checked={props.userSettings?.JSONExpanded ?? false}
                        onChange={_setJSONExpanded}
                      />
                      <Typography
                        variant="button"
                        sx={{ paddingRight: "6px", fontWeight: "bold" }}
                      >
                        {"JSON"}
                      </Typography>
                      <VscJson size={22} />
                    </Stack>
                  )}
                </Stack>
              </Grid>
              {!props.disablePaths && (
                <Grid item xs={12}>
                  <Collapse in={props.userSettings?.pathsExpanded}>
                    <PathsView
                      fetchOnOpen
                      open={props.userSettings?.pathsExpanded}
                      node={props.values}
                      maxHeight={400}
                      root={props.root}
                      paths={props.paths}
                      registryVersion={props.registryVersion}
                      SET_PATHS={props.SET_PATHS}
                    />
                  </Collapse>
                </Grid>
              )}
              {!props.disableJSON && (
                <Grid item xs={12}>
                  <Collapse in={props.userSettings?.JSONExpanded}>
                    <Box paddingBottom={"8px"}>
                      <Box padding={"8px"} sx={{ overflow: "auto" }}>
                        {props.values && <ReactJson src={props.values} />}
                      </Box>
                      <Divider />
                    </Box>
                  </Collapse>
                </Grid>
              )}
            </>
          )}

          {formBox.rows.map((row, rowIndex) =>
            row.map((input, inputIndex) => {
              const key = `FormComponent${index}${rowIndex}${inputIndex}`;
              return (
                <Grid
                  key={key}
                  item
                  xs={12 / row.length}
                  sx={{ paddingTop: "16px" }}
                >
                  <FormComponent
                    t={props.t}
                    disabled={props.saving}
                    registryVersion={props.registryVersion}
                    treeRoot={props.treeRoot}
                    productsMap={props.productsMap}
                    nodesMap={props.nodesMap}
                    wholeSalers={props.wholeSalers}
                    input={input}
                    values={props.values}
                    errors={props.errors}
                    values2={props.values2}
                    handleChange={props.handleChange}
                    registryVersions={props.registryVersions}
                  />
                </Grid>
              );
            })
          )}
        </React.Fragment>
      );
    } else return null;
  });
}

/**
 * Node Form component.
 *
 * Renders a dynamic form using provided form data object.
 * Available components are textField, picker, autoComplete and suppliers.
 * @component
 * @param {Object} props props for the component
 * @param {Function} props.t - translation function
 * @param {Object} props.values - initial nodes values
 * @param {Object} props.nodeId - optional node id, if provided the most recent node will be fetched on mount
 * @param {String} props.registryVersion - registryVersion of the node
 * @param {String} props.disableEditing - disables editing even if viewing editable version
 * @param {String} props.treeRoot - tree root e.g. "packets"
 * @param {Object} props.nodeId2 - second node id, used for comparing values, form will highlight changed fields
 * @param {String} props.registryVersion2 - registryVersion of the second node
 * @param {Object} props.values2 - initial second nodes values
 * @param {Boolean} props.hidePaths - whether to hide nodes paths
 * @param {Object} props.productsMap - products map from redux
 * @param {Object} props.nodesMap - nodes map from redux
 * @param {Object} props.form - form object for generating the fields
 * @param {Function} props.SET_CHILDREN - redux action to set tree children
 * @param {Function} props.SET_NODES - redux action to set items
 * @param {Function} props.onSave - form save callback function
 * @param {Function} props.onNodeDelete - node deletion callback function
 * @param {Object} props.userSettings - userSettings from redux
 * @param {String} props.title - main title for form
 * @param {Number} [props.margin] - forms margin
 * @param {Function} [props.onClose] - close button callback
 * @param {Boolean} [props.saving] - parents saving state
 * @param {Object} props.wholeSalers - wholeSalers from redux
 * @param {Boolean} [props.oldVersionChange] - indicates that the current node/values are from an old registryVersion
 * @param {JSX} [props.saveBtnIcon] - save button icon override
 * @param {String} [props.saveBtnTitle] - save button title override
 * @param {String} [props.disablePaths] - disable paths view
 * @param {String} [props.disableJSON] - disable JSON view
 * @param {String} [props.tabId] - tab id if form is a flexlayout tab
 * @param {String} [props.tabConfig] - tab config if form is a flexlayout tab
 * @param {String} [props.tabModel] - flexlayout model if form is a flexlayout tab
 * @returns JSX
 */
function Form(props) {
  const [values, setValues] = useState();
  const [values2, setValues2] = useState();
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState({});

  const isMissingNode = !props.nodeId && props.nodeCode;
  const editable =
    !isMissingNode &&
    (props.editable ||
      (!props.disableEditing &&
        typeof props.registryVersion === "string" &&
        props.registryVersion.includes("draft")));

  useEffect(() => {
    if (props.values) {
      setValues(props.values);
    }
  }, [props.values]);

  // TODO clear values when id changes
  // TODO disable editing until nodes have been updated
  // TODO clear values after save so the most recent value from db is shown
  // should fetch the new node after we update props.node with props.onSave
  useEffect(() => {
    // fetch newest node
    // if props.compare and props.isRemovedNode there's no need to fetch
    if (
      props.nodeId &&
      props.registryVersion &&
      (props.compare ? !props.isRemovedNode : true)
    ) {
      requestNodes([props.nodeId], props.registryVersion).then((res) => {
        if (res.status >= 200 && res.status < 300) {
          setValues(res.data[0]);
          props.SET_NODES({
            nodes: res.data,
            registryVersion: props.registryVersion,
          });
        } else {
          toast.error(
            `${props.t("failedToFetch")}: v.${
              typeof props.registryVersion === "string"
                ? props.registryVersion.split("_")[0]
                : ""
            } ${props.nodeId}`
          );
          setValues(null);
        }
      });
    } else if (!props.nodeId && props.nodeCode) {
      setValues({ code: props.nodeCode });
    } else {
      setValues(props.values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.nodeId, props.nodeCode, props.registryVersion]);

  useEffect(() => {
    // fetch newest node for compare
    // if props.compare and props.isAddedNode there's no need to fetch
    if (
      props.nodeId &&
      props.registryVersion2 &&
      props.registryVersion !== props.registryVersion2 &&
      (props.compare ? !props.isAddedNode : true)
    ) {
      requestNodes([props.nodeId], props.registryVersion2).then((res) => {
        if (res.status >= 200 && res.status < 300) {
          setValues2(res.data[0]);
          props.SET_NODES({
            nodes: res.data,
            registryVersion: props.registryVersion,
          });
        } else {
          toast.error(
            `${props.t("failedToFetch")}: v.${
              typeof props.registryVersion2 === "string"
                ? props.registryVersion2.split("_")[0]
                : ""
            } ${props.nodeId}`
          );
          setValues2(null);
        }
      });
    } else if (props.values2) {
      setValues2(props.values2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.nodeId, props.registryVersion2]);

  const handleChange =
    (key, numeric, idProp, addToArray, removeFromArray, format, overrideFn) =>
    (value) => {
      handleObjectChange(
        editable,
        key,
        numeric,
        idProp,
        addToArray,
        removeFromArray,
        format,
        setValues,
        setErrors,
        values,
        overrideFn
      )(value);
    };

  const handleItemChange = (key, numeric, id, code) => (value) => {
    if (editable) {
      // TODO is amount int
      setValues((_values) =>
        update(_values, {
          items: {
            $apply: (_items) => {
              const index = _items.findIndex((x) => {
                if (id) {
                  return x.id === id;
                } else {
                  return x.code === code;
                }
              });
              const newVal = onTextChange(value, numeric);
              return update(_items, {
                [index]: { [key]: { $set: newVal } },
              });
            },
          },
        })
      );
    }
  };

  const handleAddItem = (item) => {
    if (item && editable) {
      setValues((_values) =>
        update(_values, {
          items: {
            $autoArray: {
              $apply: (_items) => {
                if (_items.findIndex((x) => x.id === item.id) === -1) {
                  return update(_items, {
                    $push: [
                      {
                        code: item.code,
                        amount: 1,
                        id: item.id,
                        nodeType: item.nodeType,
                        type: item.type,
                        unit: "",
                      },
                    ],
                  });
                } else {
                  return _items;
                }
              },
            },
          },
        })
      );
    }
  };

  const handleRemoveItem = (item) => {
    if (item && editable) {
      setValues((_values) =>
        update(_values || props.node, {
          items: {
            $autoArray: {
              $apply: (_items) => _items.filter((x) => x.id !== item.id),
            },
          },
        })
      );
    }
  };

  const validateValues = () => {
    let valid = true;
    let _errors = {};

    props.form.data.forEach((formBox, index) => {
      formBox.rows.forEach((row, rowIndex) => {
        row.forEach((node, nodeIndex) => {
          if (node.required && !isFilled(values?.[node.key])) {
            valid = false;
            _errors[node.key] = true;
          }
        });
      });
    });

    if (!valid) {
      setErrors(_errors);
    }
    return valid;
  };

  const handleIsDraftChange = (e) => {
    setValues({...values, isDraft: e.target.checked});
  }

  const handleSave = () => {
    if (validateValues()) {
      if (props.nodeId) {
        request(
          "nodes/edit",
          "put",
          {
            node: values,
          },
          { registryVersion: props.registryVersion }
        ) // props.editUrl ?
          .then((res) => {
            if (res.status === 200) {
              props.SET_NODES({
                nodes: [res.data],
                registryVersion: props.registryVersion,
              });
              // update the tree node if the node is a package so its items get updated
              if (res.data.nodeType === 2) {
                props.UPDATE_NODE({
                  root: props.treeRoot,
                  registryVersion: props.registryVersion,
                  node: res.data,
                });
              }
              // fetch children if node is a folder or itemTree so children will be updated for tree
              if (
                (res.data.nodeType === 2 || res.data.nodeType === 4) &&
                props.treeRoot
              ) {
                getChildren(
                  props.SET_CHILDREN,
                  false,
                  props.SET_NODES,
                  0,
                  `${res.data.path}${res.data.id},`,
                  props.reduxRoot,
                  props.treeRoot,
                  res.data.items,
                  props.productsMap,
                  props.nodesMap,
                  null,
                  props.registryVersion
                );
              }
              props.onSave && props.onSave(res.data);
              toast.success(props.t("saveSuccessful"));
            }
          })
          .finally(() => setSaving(false));
      } else {
        setSaving(false);
        props.onSave(values);
      }
    } else {
      setSaving(false);
    }
  };

  useEffect(() => {
    if (saving) {
      handleSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saving]);

  const onNodeDelete = useCallback(
    (_node) => {
      props.onNodeDelete && props.onNodeDelete(_node, props.tabId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onNodeDelete, props.tabId]
  );

  const onNodeMoved = useCallback(
    (newPath) => {
      setValues((_values) => ({ ..._values, path: newPath }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValues]
  );

  return (
    <Paper
      sx={{
        minWidth: 400,
        width: props.width ?? '100%',
        height: props.height,
        overflow: 'auto',
      }}
    >
      {editable && !props.disableEditButtons ? (
        <EditTreeControls
          node={values}
          treeRoot={props.treeRoot}
          reduxRoot={props.reduxRoot}
          // TODO handle callbacks => save to values (what if node is pending an edit)
          // TODO should moving node etc trigger a fetch from backend
          onDelete={onNodeDelete}
          onNodeMoved={onNodeMoved}
          // setNode={props.setNode}
          // onDelete={props.handleSetNode}
        />
      ) : null}
      <Grid container p={2}>
        {props.title && (
          <Grid item xs={12}>
            <Typography variant="h6">{props.t(props.title)}</Typography>
          </Grid>
        )}

        <Grid container spacing={2}>
          {props.isRemovedNode ? null : (
            <Grid item xs={props.compare && !props.isAddedNode ? 6 : 12}>
              {values === null ? null : (
                <FormView
                  form={props.form}
                  t={props.t}
                  onClose={props.onClose}
                  userSettings={props.userSettings}
                  SET_SETTING={props.SET_SETTING}
                  saving={props.saving || saving}
                  registryVersion={props.registryVersion}
                  treeRoot={props.treeRoot}
                  productsMap={props.productsMap}
                  nodesMap={props.nodesMap}
                  wholeSalers={props.wholeSalers}
                  values={values}
                  values2={values2}
                  errors={errors}
                  handleChange={handleChange}
                  root={props.treeRoot}
                  disablePaths={props.disablePaths}
                  disableJSON={props.disableJSON}
                  paths={props.paths}
                  SET_PATHS={props.SET_PATHS}
                  registryVersions={props.registryVersions}
                />
              )}

              {values?.nodeType === 2 && (
                <ItemsView
                  t={props.t}
                  // width={props.isAddedNode ? "100%" : "50%"}
                  maxHeight={504}
                  paperElevation={0}
                  values={values}
                  title={props.t('items')}
                  treeRoot={props.treeRoot}
                  items={values.items}
                  items2={values2?.items}
                  selectedNode={values}
                  editable={editable}
                  registryVersion={props.registryVersion}
                  registryVersion2={props.registryVersion2}
                  oldItems={props.oldVersionChange}
                  onAddItem={handleAddItem}
                  onRemoveItem={handleRemoveItem}
                  onItemChange={handleItemChange}
                />
              )}
            </Grid>
          )}

          {(props.compare || props.isRemovedNode) &&
          props.registryVersion2 &&
          !props.isAddedNode ? (
            <Grid item xs={props.isRemovedNode ? 12 : 6}>
              {values === null ? null : (
                <FormView
                  form={props.form}
                  t={props.t}
                  onClose={props.onClose}
                  userSettings={props.userSettings}
                  SET_SETTING={props.SET_SETTING}
                  saving={props.saving || saving}
                  registryVersion={props.registryVersion2}
                  treeRoot={props.treeRoot}
                  productsMap={props.productsMap}
                  nodesMap={props.nodesMap}
                  wholeSalers={props.wholeSalers}
                  values={values2}
                  values2={values}
                  errors={errors}
                  handleChange={handleChange}
                  root={props.treeRoot}
                  disablePaths={props.disablePaths}
                  disableJSON={props.disableJSON}
                  paths={props.paths}
                  SET_PATHS={props.SET_PATHS}
                  registryVersions={props.registryVersions}
                />
              )}

              {values2?.nodeType === 2 && (
                <ItemsView
                  t={props.t}
                  maxHeight={504}
                  paperElevation={0}
                  // width={props.isRemovedNode ? "100%" : "50%"}
                  values={values2}
                  title={props.t('items')}
                  treeRoot={props.treeRoot}
                  items={values2.items}
                  items2={values?.items}
                  selectedNode={values}
                  editable={editable}
                  registryVersion={props.registryVersion2}
                  registryVersion2={props.registryVersion}
                  oldItems={props.oldVersionChange}
                  onAddItem={handleAddItem}
                  onRemoveItem={handleRemoveItem}
                  onItemChange={handleItemChange}
                />
              )}
            </Grid>
          ) : null}
        </Grid>

        {values !== null && editable ? (
          <>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values?.isDraft || false}
                    onChange={handleIsDraftChange}
                    sx={{ '&.Mui-checked': {color: '#ed8113'}}}
                  />
                }
                label={props.t('isDraft')}
              />
            </FormGroup>

            <Grid
              item
              xs={12}
              sx={{
                paddingTop: 2,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <LoadingButton
                disabled={props.saving || saving}
                variant="contained"
                loading={props.saving || saving}
                loadingPosition="end"
                endIcon={props.saveBtnIcon || <MdSave />}
                onClick={() => setSaving(true)}
                color="basic"
              >
                {props.saveBtnTitle || props.t('save')}
              </LoadingButton>
            </Grid>
          </>
        ) : null}
      </Grid>
    </Paper>
  );
}

const mapStateToProps = (state, ownProps) => {
  const registryVersion =
    ownProps.registryVersion || localStorage.getItem("registryVersion");
  return {
    registryVersion,
    registryVersions: state.versions.registryVersions,
    wholeSalers: state.common.wholeSalers,
    userSettings: state.common.settings,
    productsMap: state.nodes.productsMap,
    nodesMap: state.nodes.nodeMaps[registryVersion],
    paths: state.nodes.paths[registryVersion],
  };
};

export default connect(mapStateToProps, {
  UPDATE_NODE,
  SET_SETTING,
  SET_CHILDREN,
  SET_NODES,
  SET_PATHS,
})(Form);
