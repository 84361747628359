import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { AiOutlinePlusSquare, AiOutlineMinusSquare } from "react-icons/ai/";

import ClockLoader from "react-spinners/ClockLoader";

import { getName, getNodeIcon } from "../helpers/functions";

// TODO add documentation
/**
 * Node component receives current node height as a prop
 * @param {*} param0
 * @returns
 */
function TreeNode({ data, isOpen, style, setOpen, ...props }) {
  const node = data.node;
  const hasDraftAncestor = data.path.split(',').filter(x => x)
    .map(x => data.nodesMap.get(x)).filter(x => x)
    .some(x => x.isDraft);
  let location = useLocation();
  let searchParams = new URLSearchParams(location.search);
  const initialClicked = searchParams.get("clicked");
  const initialNode = searchParams.get("node");

  const dataId = data._nodeIdPrefix + data.id;
  const nestingLevel = data.nestingLevel;
  const [loading, setLoading] = useState(false);

  const _setOpen = (val) => {
    if (!data.disableOpennessStatePersist) {
      const opennessState =
        JSON.parse(localStorage.getItem(data.opennessStatePersistProp)) || {};
      opennessState[dataId] = val;
      localStorage.setItem(
        data.opennessStatePersistProp,
        JSON.stringify(opennessState)
      );
    }
    setOpen(val);
  };

  // TODO throttle or queue children fetch
  const fetchNodeChildren = async (toggleAfterFetch = true, fn, toggle) => {
    setLoading(true);
    await data.getChildren(
      data.childrenLength,
      `${data.path}${node.id},`,
      data.items,
      data.nodeRegistryVersion,
      data.versionChanges
    );
    if (toggleAfterFetch) toggle(!isOpen);
    if (fn) fn();
    setLoading(false);
  };

  useEffect(() => {
    if (
      data.isOpenByDefault &&
      data.childrenLength === 0 &&
      (node.nodeType === 1 || node.nodeType === 2 || node.nodeType === 4)
    ) {
      // TODO deleted folder should request its children from old version
      fetchNodeChildren(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.isOpenByDefault, data.childrenLength]);

  const handleClick = (toggle) => {
    if (node.type === 5) {
      if (data.isLeaf) {
        toggle(!isOpen);
      }
    } else if (node.nodeType === 6) {
    } else if (data.isLeaf) {
      if (data.childrenLength > 0) {
        toggle(!isOpen);
      } else {
        fetchNodeChildren(true, null, toggle);
      }
    } else if (node.type === 10) {
    } else {
      toggle(!isOpen);
    }
  };

  const handleOpen = async () => {
    if (!loading && !data.supplierName) {
      handleClick(() => _setOpen(!isOpen));
    }
  };

  const handleSelect = (ev) => {
    if (!window.getSelection().isCollapsed || node.nodeType === 7) {
      return;
    }

    const el = ev.target;
    if (ev.shiftKey || ev.ctrlKey) {
      if (data.selectedNodes.current.has(el.id)) {
        data.selectedNodes.current.delete(el.id);
        el.classList.remove("selected-node");
      } else {
        data.selectedNodes.current.add(el.id);
        el.classList.add("selected-node");
      }
    } else {
      data.selectedNodes.current.forEach((elId) => {
        const _el = document.getElementById(elId);
        if (_el) {
          _el.classList.remove("selected-node");
        }
      });
      data.selectedNodes.current.clear();
      data.selectedNodes.current.add(el.id);
      el.classList.add("selected-node");
    }
  };

  const onDoubleClick = (ev, openInNewTab) => {
    ev.preventDefault();
    ev.stopPropagation();
    if (node.nodeType === 7) {
      return;
    }

    if (data.versionChanges) {
      // TODO check if item is found
      const item = node;
      const changes = data.versionChanges[node.id];
      data.setSelectedNode(
        { ...item, ...node, changes },
        data.tabConfig,
        openInNewTab
      );
    } else {
      data.setSelectedNode &&
        data.setSelectedNode(
          {
            ...node,
          },
          data.tabConfig,
          openInNewTab
        );
    }
  };

  const onAuxClick = (ev) => {
    if (ev.button === 1) {
      ev.preventDefault();
      ev.stopPropagation();
      onDoubleClick(ev, true);
    }
  };

  // const [refCallback] = useDoubleClick(null, onDoubleClick);

  const paddingLeft = nestingLevel ? 20 + nestingLevel * 20 : 20;
  let nestingIndicators = [];
  for (let i = 1; i <= nestingLevel; i++) {
    // TODO doesn't have unique id for some reason
    nestingIndicators.push(
      <div
        key={dataId + "-nestingIndicator-" + i}
        style={{
          position: "absolute",
          top: 0,
          left: 10 + i * 20,
          width: 1,
          height:
            data.isLastChild &&
            i === nestingLevel &&
            (data.isLeaf ? !isOpen : true)
              ? 10
              : 20,
          backgroundColor: "#666",
        }}
      />
    );
  }

  // TODO hover color doesn't work for content overflowing width
  return (
    <div
      id={dataId}
      style={{
        ...style,
        paddingLeft: paddingLeft,
        width: `calc(100% - ${paddingLeft}px)`,
        // width: "100%",
        backgroundColor:
          initialNode === node.id
            ? "#edc491"
            : initialClicked === node.id
            ? "#faba6b"
            : data.backgroundColor,
      }}
      className={"node-row"} //noselect
      onAuxClick={onAuxClick}
      onDoubleClick={onDoubleClick}
      // ref={refCallback}
    >
      {nestingLevel > 0 && (
        <div
          style={{
            position: "absolute",
            top: 10,
            left: paddingLeft - 10,
            width: 10,
            height: 1,
            backgroundColor: "#666",
          }}
        />
      )}
      {nestingIndicators}

      {data.isLeaf && (
        <div className="node-icon" onClick={handleOpen}>
          {isOpen ? (
            <AiOutlineMinusSquare size={20} color={"#000"} />
          ) : (
            <AiOutlinePlusSquare size={20} color={"#000"} />
          )}
        </div>
      )}

      <div
        id={dataId + (node.nodeType === 7 ? "-supplier" : "-text")}
        ref={props.selectableRef}
        className={
          "flex-row clickable-tree-node" +
          (data.selectedNodes.current.has(dataId + "-text")
            ? " selected-node"
            : "")
        }
        onClick={handleSelect}
      >
        <div className={`node-icon ${(node.isDraft || hasDraftAncestor) && 'draft-color'}`}>
          {loading ? (
            <ClockLoader size={14} color={"#123abc"} loading={true} />
          ) : (
            getNodeIcon(isOpen, node.nodeType, node.type, data.isEmpty)
          )}
        </div>

        {getName(node, data.treeRoot, data.wholeSalers) || node.id}
      </div>
    </div>
  );
}

export default TreeNode;
