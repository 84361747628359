import React, {
  useReducer,
  useCallback,
  useRef,
  useEffect,
  useState,
} from "react";
import update from "immutability-helper";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Selecto from "react-selecto";

import { FixedSizeTree } from "react-vtree";

import PulseLoader from "react-spinners/PulseLoader";
import ClockLoader from "react-spinners/ClockLoader";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import LinearProgress from "@mui/material/LinearProgress";

import { VscError, VscCollapseAll } from "react-icons/vsc";
import {
  MdOutlineCheckCircleOutline,
  MdSettings,
  MdQuestionMark,
} from "react-icons/md";

import Tooltip from "./Tooltip";
import Treenode from "./TreeNode";
import TreeToolButton from "./TreeToolButton";

import { movePermissions } from "../helpers/constants";
import { getVersionChanges } from "../helpers/requests";
import { request } from "../helpers/api";
import {
  getNodeIcon,
  getName,
  mergeVersionChanges,
  pathToIds,
  checkIfArr,
  getVersionChangeNodeBGColor,
  getDeletedItemsWithId,
  checkIfItemIsAddedItem,
} from "../helpers/functions";
import { getChildren } from "../helpers/getChildren";

import { connect } from "react-redux";
import {
  UPDATE_NODE,
  DELETE_NODE,
  SET_CHILDREN,
  SET_MULTIPLE_CHILDREN,
} from "../actions/TreeActions";
import { SET_NODES } from "../actions/ItemsActions";
import { SET_VERSION_CHANGES } from "../actions/VersionsActions";
import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";

const treeRoots = ["packets", "nomenclatures", "products", "tables"];

function createNodeId(node) {
  return node.path + node.id + ",";
}

// This helper function constructs the object that will be sent back at the step
// [2] during the treeWalker function work. Except for the mandatory `data`
// field you can put any additional data here.
const getNodeData = (
  node,
  treeNode,
  nestingLevel,
  isLastChild,
  props,
  treeRef
) => {
  const {
    productsMap,
    nodesMap,
    registryVersion,
    editable,
    SET_NODES,
    SET_CHILDREN,
    setSelectedNode,
    setItemToCompare,
    treeRoot,
    reduxRoot,
    nodeIdPrefix,
    versionChanges,
    width,
    disableOpennessStatePersist,
    useRootPath,
    tabConfig,
    // from parent component
    initialOpennessState,
    initialPath,
    initialNode,
    scrollStateRef,
    _nodeIdPrefix,
    opennessStatePersistProp,
    _selectedNodes,
  } = props;

  const { loading, children = [] } = treeNode;
  const uniqueId = treeNode.path + (node.id || node.code) + ",";
  const childrenLength = children.length;
  // TODO packets with modified items or packages inside of them don't show as modified parents
  // TODO => does this need a fix
  const childrenModified = versionChanges?.modifiedParents
    ? versionChanges?.modifiedParents.includes(node.id)
    : false;

  const deletedItems = getDeletedItemsWithId(versionChanges, node.id);

  if (deletedItems.length > 0) {
    deletedItems.forEach((deletedItem, index) => {
      deletedItems[index] = deletedItem;
    });
  }

  const isSearchItem =
    initialNode &&
    (initialNode === node.id || uniqueId === initialPath + initialNode);

  const isOpenByDefault = props.isOpenByDefault
    ? true
    : initialOpennessState[uniqueId] ||
      (initialPath ? initialPath.startsWith(uniqueId) : false);

  const isLeaf =
    node.nodeType === 1 ||
    node.nodeType === 2 ||
    node.nodeType === 4 ||
    node.type === 10 ||
    (node.nodeType === 5 && treeRoot === "products");

  const backgroundColor =
    // isSearchItem
    //   ? "#ffff00"
    //   : initialClicked === node._id
    //   ? "#faba6b"
    //   :
    node.self === 3
      ? "#c79999"
      : node.self === 2
      ? "#b9d4b4"
      : getVersionChangeNodeBGColor(versionChanges, node._id) ||
        (childrenModified ? "#a6c8ff" : "#fff");

  return {
    data: {
      node: node,
      ...treeNode,
      id: uniqueId,
      isSearchItem,
      isOpenByDefault,
      isLeaf,
      backgroundColor,
      deletedItems,
      childrenModified,
      defaultHeight: 20,
      // from props
      width,
      _nodeIdPrefix,
      opennessStatePersistProp,
      productsMap,
      nodesMap,
      registryVersion,
      editable,
      SET_NODES,
      SET_CHILDREN,
      setSelectedNode,
      setItemToCompare,
      treeRoot,
      reduxRoot,
      nodeIdPrefix,
      isLastChild,
      nestingLevel,
      childrenLength,
      loading,
      versionChanges,
      treeRef,
      disableOpennessStatePersist,
      useRootPath,
      scrollStateRef,
      selectedNodes: _selectedNodes,
      tabConfig,
      getChildren: props.getChildren,
      wholeSalers: props.wholeSalers,
    },
    nestingLevel,
    treeNode,
  };
};

// The `treeWalker` function runs only on tree re-build which is performed
// whenever the `treeWalker` prop is changed.
function* treeWalker(treeRef, props) {
  const {
    tree,
    productsMap,
    nodesMap,
    versionChanges,
    renderSuppliers,
    hideDrafts
    // wholeSalers,
  } = props;

  // Step [1]: Define the root node of our tree. There can be one or
  // multiple nodes.
  for (let i = tree.length - 1; i > -1; i--) {
    const node = tree[i];
    const _node =
      node.type === 3 ? productsMap.get(node.id) : nodesMap?.get?.(node.id);
    yield getNodeData(_node, node, 0, i === 0, props, treeRef);
  }

  while (true) {
    // Step [2]: Get the parent component back. It will be the object
    // the `getNodeData` function constructed, so you can read any data from it.
    const { treeNode, data, nestingLevel } = yield;

    const treeParent = treeNode;
    const { deletedItems = [] } = data;
    const { children = [] } = treeParent;
    const parent =
      treeParent.type === 3
        ? productsMap.get(treeParent.id)
        : nodesMap?.get?.(treeParent.id);
    const childrenLength = children.length;

    const path = treeParent.path + treeParent.id + ",";

    for (let j = 0; j < childrenLength; j++) {
      const child = children[j];

      const map = child.type === 3 ? productsMap : nodesMap;

      const _node = {
        ...treeParent.items?.find((x) => x.id === child.id),
        ...map?.get?.(child.id),
        self: checkIfItemIsAddedItem(versionChanges, parent.id, child.id)
          ? 2
          : child.self,
      };

      const nodeData = getNodeData(
        _node,
        {
          ...child,
          path,
        },
        nestingLevel + 1,
        j === childrenLength - 1,
        props,
        treeRef
      );
      if(!nodeData.data.node.isDraft || !hideDrafts)
        yield nodeData;
    }

    // normal nodes have items as children, versionChange nodes have nested items
    if (deletedItems.length > 0) {
      for (let k = 0; k < deletedItems.length; k++) {
        const item = deletedItems[k];

        const nodeData = getNodeData(
          {
            ...item,
            id: item.id,
            path: ",",
            self: checkIfItemIsAddedItem(versionChanges, treeParent.id, item.id)
              ? 2
              : item.self,
          },
          { ...item, path },
          nestingLevel + 1,
          k === deletedItems.length - 1,
          props,
          treeRef
        );
        if(!nodeData.data.node.isDraft || !hideDrafts)
          yield nodeData;
      }
    }

    const hasSuppliers = parent?.suppliers && parent.suppliers.length > 0;

    // map suppliers as children if parent has them
    if (hasSuppliers && renderSuppliers) {
      const itemToUse = productsMap.get(treeParent.id);

      for (let l = 0; l < itemToUse.suppliers.length; l++) {
        const supplier = itemToUse.suppliers[l];

        const nodeData = getNodeData(
          {
            ...supplier,
            // supplierName: wholeSalers[supplier.id - 1]?.name,
            id: supplier.id,
            path: parent.path + supplier.id + ",",
          },
          { ...supplier, path },
          nestingLevel + 1,
          l === itemToUse.suppliers - 1,
          props,
          treeRef
        );
        if(!nodeData.data.node.isDraft || !hideDrafts)
          yield nodeData;
      }
    }
  }
}

const clearSelectedNodes = (_selectedNodes) => {
  _selectedNodes.current.forEach((elId) => {
    const _el = document.getElementById(elId);
    if (_el) {
      _el.classList.remove("selected-node");
    }
  });
  _selectedNodes.current.clear();
};

function NodeDeletionProgress({ current, total, title }) {
  return (
    <Box
      sx={{
        paddingTop: 2,
        width: "100%",
      }}
    >
      <LinearProgress variant="determinate" value={(current / total) * 100} />
      <Box
        sx={{
          paddingTop: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h6">{title ?? `${current}/${total}`}</Typography>
      </Box>
    </Box>
  );
}

function NodeDeletionDialogContent({
  t,
  treeRoot,
  nodes,
  nodeResults,
  current,
  nodesProcessed,
  isStarted,
  isDone,
  progressTitle,
}) {
  return (
    <>
      <Box sx={{ maxHeight: 400, overflowY: "auto" }}>
        {nodes.map((node) => {
          const deletionRes = nodeResults?.[node.id];
          return (
            <Tooltip key={"deletionPreview" + node.id} tip={deletionRes?.error}>
              <div
                id={"deletionPreview" + node.id}
                className={"node-row no-hover-bolding"}
              >
                <div className={"flex-row clickable-tree-node"}>
                  {isStarted && (
                    <div className="node-icon">
                      {deletionRes?.error ? (
                        <VscError size={18} color="red" />
                      ) : deletionRes?.res ? (
                        <MdOutlineCheckCircleOutline size={18} color="green" />
                      ) : (
                        <ClockLoader
                          size={18}
                          color={"#123abc"}
                          loading={true}
                        />
                      )}
                    </div>
                  )}
                  <div className="node-icon">
                    {getNodeIcon(false, node.nodeType, node.type)}
                  </div>

                  {getName(node, treeRoot) || node.id}
                </div>
              </div>
            </Tooltip>
          );
        })}
      </Box>
      {isStarted && (
        <NodeDeletionProgress
          title={isDone && progressTitle + ` ${nodesProcessed}/${nodes.length}`}
          current={current}
          total={nodes.length}
        />
      )}
    </>
  );
}
/** Search functionality
 * 1. should update search ref every time url search string gets updated
 * 2. cell mount should always check if its id is in the search string, if yes scroll to self after fetching children
 * 3. should be able to scroll on cells that are mounter already so cells should listen to search string changes too
 */
/**
 * Node Tree component.
 * @component
 * @param {Object} props props for the component
 * @returns JSX
 */
// TODO persist constant versions, not draft versions
function NodeTree(props) {
  const { t } = useTranslation();
  const editable =
    !props.disableEditing &&
    typeof props.registryVersion === "string" &&
    props.registryVersion.includes("draft");
  let history = useHistory();
  let location = useLocation();
  const [popover, setPopover] = useState({ anchorEl: null });
  const [loading, setLoading] = useState(false);
  let searchParams = new URLSearchParams(location.search);
  const linkTime = props.disableURLHandling ? "" : searchParams.get("linkTime");
  const initialPath = props.disableURLHandling ? "" : searchParams.get("path");
  const initialNode = props.disableURLHandling ? "" : searchParams.get("node");
  const scrollStateRef = useRef({ path: null });
  const { registryVersion } = props;
  const _nodeIdPrefix = props.nodeIdPrefix ? props.nodeIdPrefix + "/" : "";
  const treeRef = useRef();
  const opennessStatePersistProp =
    _nodeIdPrefix + props.reduxRoot + "/" + props.registryVersion;
  let initialOpennessState = useRef(
    JSON.parse(localStorage.getItem(opennessStatePersistProp)) || {}
  );
  const _selectedNodes = useRef(new Set());
  const selectoRef = useRef();
  const innerRef = useRef(null);
  const outerRef = useRef(null);
  const awaitingTreeRecompute = useRef();
  const awaitingScroll = useRef();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const scrollTimeout = useRef();

  const isActiveTab = () =>
    props.tabModel?.current.getActiveTabset()?.getSelectedNode()?.getId() ===
    props.tabId;

  const getNodeWithPath = (path) => {
    const _ids = pathToIds(path + ",");
    const id = _ids[_ids.length - 2];
    const node = props.productsMap.get(id) ?? props.nodesMap?.get?.(id);
    if (node) return node;
  };

  const _getChildren = useCallback(
    async (
      _childrenLength,
      _path,
      _items,
      _nodeRegistryVersion,
      _versionChanges
    ) => {
      await getChildren(
        props.SET_CHILDREN,
        props.useRootPath,
        props.SET_NODES,
        _childrenLength,
        _path,
        props.reduxRoot,
        props.treeRoot,
        _items,
        props.productsMap,
        props.nodesMap,
        _nodeRegistryVersion,
        props.registryVersion,
        _versionChanges
      );
      return true;
    },
    [
      props.SET_CHILDREN,
      props.useRootPath,
      props.SET_NODES,
      props.registryVersion,
      props.reduxRoot,
      props.treeRoot,
      props.productsMap,
      props.nodesMap,
    ]
  );

  const deleteSelectedNodes = useCallback(
    async (nodes) => {
      let nodeResults = {};
      let nodesProcessed = 0;

      const updateDialog = (current, isDone) => {
        props.setDialog({
          title: isDone ? t("deletionFinished") : t("deleting"),
          visible: true,
          content: (
            <NodeDeletionDialogContent
              t={t}
              treeRoot={props.treeRoot}
              nodes={nodes}
              nodeResults={nodeResults}
              nodesProcessed={nodesProcessed}
              current={current}
              isStarted={true}
              isDone={isDone}
              progressTitle={t("deleted")}
            />
          ),
          loading: !isDone,
          hideSaveButton: isDone ? true : false,
          cancelButtonTitle: isDone ? t("close") : undefined,
        });
      };

      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];

        try {
          // folder should return 409 if it still has children => message: You need to delete related childrens first
          // package/product/job should return 409 if it is referenced in table cells or some package => message: Node still has relations
          const res = await request(
            "nodes/" + encodeURIComponent(node.id),
            "delete",
            null,
            {
              registryVersion: props.registryVersion,
              root: props.treeRoot,
            },
            true
          );

          if (res.status === 204) {
            // TODO should delete node delete the node not only from tree but also from nodes maps
            props.DELETE_NODE({
              root: props.reduxRoot,
              registryVersion: props.registryVersion,
              node: node,
            });
            nodeResults[node.id] = { res };
            nodesProcessed++;
          } else if (res.response?.status === 409) {
            nodeResults[node.id] = {
              res,
              error: res?.response?.data?.displayMessage
                ? getName(node) + " " + res.response.data.displayMessage
                : t("deletionFailed"),
            };
          } else {
            nodeResults[node.id] = {
              res,
              error: res?.response?.data?.displayMessage || t("deletionFailed"),
            };
          }
        } catch (error) {
          nodeResults[node.id] = { error: t("deletionFailed") };
        }

        updateDialog(i + 1);
      }

      updateDialog(nodes.length, true);
    },
    [props.treeRoot, props.reduxRoot, props.registryVersion]
  );

  const moveSelectedNodes = useCallback(
    async (nodes) => {
      let it = _selectedNodes.current.values();
      //get first entry:
      let first = it.next();
      const targetNode = getNodeWithPath(first.value);

      if (targetNode) {
        let nodeResults = {};
        let nodesMoved = 0;

        const updateDialog = (current, isDone) => {
          props.setDialog({
            title: isDone ? t("moveFinished") : t("moving"),
            visible: true,
            content: (
              <NodeDeletionDialogContent
                t={t}
                treeRoot={props.treeRoot}
                nodes={nodes}
                nodeResults={nodeResults}
                nodesProcessed={nodesMoved}
                current={current}
                isStarted={true}
                isDone={isDone}
                progressTitle={t("moved")}
              />
            ),
            loading: !isDone,
            hideSaveButton: isDone ? true : false,
            cancelButtonTitle: isDone ? t("close") : undefined,
          });
        };

        for (let i = 0; i < nodes.length; i++) {
          const node = nodes[i];

          try {
            const res = await request(
              "nodes/move",
              "put",
              {
                targetId: targetNode.id,
                id: node.id,
                root: props.treeRoot,
              },
              { registryVersion: props.registryVersion },
              true
            );

            if (res.status === 200) {
              // TODO should delete node delete the node not only from tree but also from nodes maps
              props.DELETE_NODE({
                root: props.reduxRoot,
                registryVersion: props.registryVersion,
                node: node,
              });
              nodeResults[node.id] = { res };
              nodesMoved++;
            } else if (res.response?.status === 409) {
              nodeResults[node.id] = {
                res,
                error: res?.response?.data?.displayMessage
                  ? getName(node) + " " + res.response.data.displayMessage
                  : t("movingFailed"),
              };
            } else {
              nodeResults[node.id] = {
                res,
                error: res?.response?.data?.displayMessage || t("movingFailed"),
              };
            }
          } catch (error) {
            nodeResults[node.id] = { error: t("movingFailed") };
          }

          props.removeOneSelectedNode(node);
          updateDialog(i + 1);
        }

        props.removeAllSelectedNodes();
        // TODO opennessState may be a bit wonky if moving to upper or more nested folder
        _getChildren(0, `${targetNode.path}${targetNode.id},`);
        updateDialog(nodes.length, true);
      }
    },
    [
      props.treeRoot,
      props.productsMap,
      props.nodesMap,
      props.reduxRoot,
      props.registryVersion,
    ]
  );

  const handleDelete = useCallback(() => {
    let nodes = [];
    _selectedNodes.current.forEach((x) => {
      const node = getNodeWithPath(x);
      if (node) nodes.push(node);
    });

    const dialogParams = {
      title: t("deleteConfirmation"),
      content: (
        <NodeDeletionDialogContent
          t={t}
          treeRoot={props.treeRoot}
          nodes={nodes}
        />
      ),
      visible: true,
    };

    props.setDialog({
      ...dialogParams,
      fn: () => {
        props.setDialog({
          ...dialogParams,
          title: t("deleting"),
          content: (
            <NodeDeletionDialogContent
              t={t}
              treeRoot={props.treeRoot}
              nodes={nodes}
              isStarted={true}
              current={0}
            />
          ),
          loading: true,
        });
        deleteSelectedNodes(nodes);
      },
    });
    // // // // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.productsMap, props.nodesMap, props.treeRoot, _selectedNodes]);

  const handleMove = useCallback(() => {
    if (_selectedNodes.current.size === 1 && props.selectedNodes.length > 0) {
      let it = _selectedNodes.current.values();
      let first = it.next();
      const targetNode = getNodeWithPath(first.value);

      if (targetNode) {
        // nodes can only be moved to folders
        if (targetNode.nodeType !== 1 && targetNode.nodeType !== 4) {
          toast.warning(t("moveNotPermitted"));
          return;
        }

        const dialogParams = {
          title: t("moveConfirmation"),
          content: (
            <>
              <NodeDeletionDialogContent
                t={t}
                treeRoot={props.treeRoot}
                nodes={props.selectedNodes}
              />
              <Typography sx={{ marginTop: 1 }} variant="h6">
                {`${t("toTarget")}: ${getName(targetNode, props.treeRoot)}`}
              </Typography>
            </>
          ),
          visible: true,
        };

        props.setDialog({
          ...dialogParams,
          fn: () => {
            props.setDialog({
              ...dialogParams,
              title: t("moving"),
              content: (
                <NodeDeletionDialogContent
                  t={t}
                  treeRoot={props.treeRoot}
                  nodes={props.selectedNodes}
                  isStarted={true}
                  current={0}
                />
              ),
              loading: true,
            });
            moveSelectedNodes(props.selectedNodes);
          },
        });
      }
      // // // // eslint-disable-next-line react-hooks/exhaustive-deps}
    }
  }, [props.selectedNodes, props.treeRoot, _selectedNodes]);

  const handleCut = () => {
    if (props.treeRoot !== "products") {
      const _nodes = [];

      _selectedNodes.current.forEach((x) => {
        const node = getNodeWithPath(x);
        if (node) _nodes.push(node);
      }, []);

      if (_nodes.length > 0) {
        props.setSelectedNodes(_nodes);
      }
    }
  };

  const handleKeyUp = (e) => {
    if (isActiveTab()) {
      if (e.keyCode === 90 && e.ctrlKey) {
        // undo();
      } else if (e.keyCode === 89 && e.ctrlKey) {
        // redo();
      } else if (e.keyCode === 88 && e.ctrlKey) {
        // cut;
        if (editable) {
          // delete selected nodes
          handleCut();
        }
      } else if (e.key === "Delete") {
        if (editable) {
          // delete selected nodes
          handleDelete();
        }
      } else if (e.key === "Escape") {
        // clear selection
        clearSelectedNodes(_selectedNodes);
      }
    }
  };

  const handleCopy = () => {
    if (isActiveTab() && _selectedNodes.current.size > 0) {
      let text = "";
      _selectedNodes.current.forEach((x) => {
        const _ids = pathToIds(x + ",");
        const id = _ids[_ids.length - 2];
        const node = props.productsMap.get(id) ?? props.nodesMap?.get?.(id);
        if (node?.code) {
          if (text) text += "\n";
          text += node.code;
        }
      });
      // TODO add right click menu to copy full name, name or code or something else
      if (window.ClipboardItem) {
        let textType = "text/plain";
        let textBlob = new Blob([text], { type: textType });
        var data = [new window.ClipboardItem({ [textType]: textBlob })];
        navigator.clipboard.write(data).then(async (x) => {});
      } else if (navigator.clipboard) {
        navigator.clipboard.writeText(text).then(async (x) => {});
      } else {
        toast.error("Copying to clipboard failed");
      }
    }
  };

  const handlePaste = () => {
    const isActiveTab =
      props.tabModel?.current.getActiveTabset()?.getSelectedNode()?.getId() ===
      props.tabId;

    if (isActiveTab) {
      handleMove();
    }
  };

  useEffect(() => {
    document.addEventListener("copy", handleCopy);
    document.addEventListener("paste", handlePaste);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("copy", handleCopy);
      document.removeEventListener("paste", handlePaste);
      document.removeEventListener("keyup", handleKeyUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.productsMap,
    props.nodesMap,
    props.treeRoot,
    props.reduxRoot,
    props.registryVersion,
    props.selectedNodes,
    _selectedNodes,
  ]);

  useEffect(() => {
    // fetch root if props.treeRoot changes
    if (
      !props.disableRootFetch &&
      !checkIfArr(props.tree) &&
      props.reduxRoot &&
      registryVersion
    ) {
      request("nodes/rootNodes", "get", null, {
        root: props.treeRoot,
        registryVersion,
      }).then((res) => {
        if (checkIfArr(res.data)) {
          props.SET_NODES({
            nodes: res.data.map((x) => ({ ...x, isRootNode: true })),
            registryVersion,
          });
          props.SET_CHILDREN({
            root: props.reduxRoot,
            data: res.data,
            registryVersion,
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registryVersion, props.treeRoot]);

  const getVersionChangesBetweenVersions = () => {
    // TODO maybe save version changes to redux as is and merge in useMemo or something
    if (props.treeRoot && props.oldRegistryVersion && registryVersion) {
      if (
        !props.versionChanges?.[props.treeRoot]?.[
          `${props.oldRegistryVersion}-${registryVersion}`
        ]
      ) {
        setLoading(true);

        let fetchVersions = [];

        const _version1 =
          typeof props.oldRegistryVersion === "string"
            ? props.oldRegistryVersion.split("_")[0]
            : props.oldRegistryVersion;
        for (
          let i = parseInt(_version1) + 1;
          i <= parseInt(registryVersion);
          i++
        ) {
          fetchVersions.push(getVersionChanges(props.treeRoot, i));
        }

        if (fetchVersions.length > 0) {
          Promise.all(fetchVersions)
            .then((res) => {
              if (res.every((x) => !x)) {
                toast.info(t("noChangesBetweenVersions"));
              } else {
                let versionChanges;

                versionChanges = mergeVersionChanges(
                  props.lng,
                  res,
                  props.oldRegistryVersion,
                  registryVersion
                );

                props.SET_VERSION_CHANGES({
                  root: props.treeRoot,
                  oldRegistryVersion: props.oldRegistryVersion,
                  registryVersion,
                  versionChanges: versionChanges,
                });
              }
            })
            .catch((err) => {
              console.error(err);
              toast(t("errFetchingChanges"));
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      props.enableVersionChanges &&
      props.treeRoot &&
      props.oldRegistryVersion &&
      registryVersion
    ) {
      getVersionChangesBetweenVersions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.enableVersionChanges,
    props.treeRoot,
    props.oldRegistryVersion,
    registryVersion,
  ]);

  useEffect(() => {
    // 3. when state has changed open the paths nodes
    // 4. scroll to node
    if (awaitingTreeRecompute.current) {
      const _initialPath = awaitingTreeRecompute.current.initialPath;

      const opennessState = {};

      for (let i = 1; i < _initialPath.length; i++) {
        const char = _initialPath[i];
        if (char === ",") {
          opennessState[_initialPath.substring(0, i + 1)] = true;
        }
      }

      treeRef.current.recomputeTree(opennessState, false, true);

      if (scrollTimeout.current) clearTimeout(scrollTimeout.current);
      awaitingScroll.current = awaitingTreeRecompute.current;
      awaitingTreeRecompute.current = null;
    }
  }, [props.tree]);

  useEffect(() => {
    if (awaitingScroll.current) {
      // TODO doesn't scroll if user clicks a path that's already in url
      // TODO rework so we don't need timeout
      scrollTimeout.current = setTimeout(() => {
        if (awaitingScroll.current) {
          treeRef.current.scrollToItem(
            awaitingScroll.current.initialPath,
            "start"
          );
          awaitingScroll.current = null;
        }
      }, 300);
    }
  });

  const handleInitialScroll = useCallback(async () => {
    if (initialPath && treeRef.current && props.tree) {
      // 1. fetch the paths children
      // 2. set the whole path to tree
      // 3. when state has changed open the paths nodes
      // 4. scroll to node

      const ids = pathToIds(initialPath);

      let payloads = {};
      let _path = ",";
      for (let i = 0; i < ids.length; i++) {
        const id = ids[i];
        const node =
          i === 0
            ? props.tree.find((x) => x.id === id)
            : payloads[_path].childrenPayload.data.find((x) => x.id === id);

        // if node is table, it won't have children
        if (!node || node.nodeType === 6) {
          break;
        }
        const items = node.items;
        _path += id + ",";
        const payload = await getChildren(
          null,
          false,
          null,
          0,
          _path,
          props.reduxRoot,
          props.treeRoot,
          items,
          props.productsMap,
          props.nodesMap,
          null,
          props.registryVersion,
          props.versionChanges,
          true
        );
        payloads[_path] = payload;
      }

      let nodesPayload;
      const childrenPayload = {
        payloads: Object.values(payloads).map((x) => {
          if (x.nodesPayload) {
            nodesPayload = update(nodesPayload, {
              $auto: {
                nodes: { $autoArray: { $push: x.nodesPayload.nodes } },
                registryVersion: { $set: x.nodesPayload.registryVersion },
              },
            });
          }
          return update(x.childrenPayload, {
            $auto: {
              useOldChildren: { $set: true },
            },
          });
        }),
      };

      // set ref so we know when to recompute tree in useEffect
      awaitingTreeRecompute.current = {
        initialPath,
        ids,
      };

      if (nodesPayload) {
        props.SET_NODES(nodesPayload);
      }
      props.SET_MULTIPLE_CHILDREN(childrenPayload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.treeRoot, props.tree, initialPath, treeRef]);

  useEffect(() => {
    // set opennesState if path in url changes
    handleInitialScroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeRef.current, initialPath, linkTime]);

  const collapseAllNodes = useCallback(async () => {
    localStorage.removeItem(props.treeRoot);
    history.replace(location.pathname);

    await treeRef.current?.recomputeTree(
      Object.fromEntries(
        // Note: This is for a tree with multiple root nodes
        props.tree.map((n) => [
          createNodeId(n),
          {
            open: false,
            subtreeCallback: (node) => {
              // NOTE: this is permitted by the library and is faster than using setOpen
              node.isOpen = false;
            },
          },
        ])
      ),
      false,
      true
    );
    handlePopoverClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.treeRoot, treeRef.current, props.tree]);

  const handlePopoverClose = () => {
    setPopover({ anchorEl: null });
  };

  const handleSettingsOpen = (event) => {
    setPopover({ anchorEl: event.currentTarget });
  };

  const isProductRoot = props.treeRoot === "products";
  const _treeWalker = useCallback(() => {
    return treeWalker(treeRef, {
      ...props,
      renderSuppliers: isProductRoot,
      props,
      isProductRoot,
      initialOpennessState: initialOpennessState.current,
      _nodeIdPrefix,
      opennessStatePersistProp,
      initialPath,
      initialNode,
      scrollStateRef,
      _selectedNodes,
      getChildren: _getChildren,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, isProductRoot, initialPath, initialNode]);

  const handleSelectionStart = useCallback(
    (e) => {
      if (!e.inputEvent.shiftKey && !e.inputEvent.ctrlKey) {
        clearSelectedNodes(_selectedNodes);
      }
      forceUpdate();
      selectoRef.current.selecto.findSelectableTargets();
    },
    [selectoRef, _selectedNodes]
  );

  const handleSelect = useCallback(
    (e) => {
      // event can be accessed with e.inputEvent
      e.added.forEach((el) => {
        if (el.id.endsWith("-text")) {
          _selectedNodes.current.add(el.id);
          el.classList.add("selected-node");
        }
      });
      e.removed.forEach((el) => {
        _selectedNodes.current.delete(el.id);
        el.classList.remove("selected-node");
      });
    },
    [_selectedNodes]
  );

  // console.log("TREEEEEEEEEEEEEEE", props.tree);
  // console.log("VALUESSSSSSSSSSSS", props.nodesMap?.values?.());
  // console.log("PRODUCTSSSSSSSSSS", props.productsMap?.values?.());
  const open = Boolean(popover.anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      {!props.disableSettings && (
        <Box sx={{ position: 'absolute', top: 8, right: 0, zIndex: 1000 }}>
          <TreeToolButton tooltip={t('searchInfo')} icon={<MdQuestionMark size="18px" />} />
          <TreeToolButton
            tooltip={t('settings')}
            onClick={handleSettingsOpen}
            icon={<MdSettings size="18px" />}
          />
        </Box>
      )}
      {loading || props.loading ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: 20,
            fontSize: 12,
          }}
        >
          <PulseLoader
            //css={override}
            size={8}
            color={'#123abc'}
            loading={true}
          />
        </div>
      ) : props.tree && props.tree.length > 0 ? (
        <>
          <Selecto
            ref={selectoRef}
            dragCondition={(e) => !props.disabled}
            // The container to add a selection element
            container={outerRef.current}
            // The area to drag selection element (default: container)
            // dragContainer={document.getElementById(props.dragContainerId)} //props.dragContainer}
            // Targets to select. You can register a queryselector or an Element.
            selectableTargets={document.querySelectorAll('.clickable-tree-node')}
            // Whether to select by click (default: true)
            selectByClick={false}
            // Whether to select from the target inside (default: true)
            selectFromInside={false}
            // After the select, whether to select the next target with the selected target (deselected if the target is selected again).
            continueSelect={false}
            // Determines which key to continue selecting the next target via keydown and keyup.
            toggleContinueSelect={[['shift'], ['ctrl']]}
            toggleContinueSelectWithoutDeselect={[['shift'], ['ctrl']]}
            // The container for keydown and keyup events
            keyContainer={window}
            // The rate at which the target overlaps the drag area to be selected. (default: 100)
            hitRate={1}
            onDrag={props.onDrag}
            onDragStart={handleSelectionStart}
            onSelect={handleSelect}
          />
          <FixedSizeTree
            innerRef={innerRef}
            outerRef={outerRef}
            className={opennessStatePersistProp + '_tree'}
            ref={treeRef}
            itemSize={20}
            //MOVE SHARED NODE PROPS HERE
            //itemData={}
            treeWalker={_treeWalker}
            height={props.height}
            width={props.width}
            async
          >
            {Treenode}
          </FixedSizeTree>
        </>
      ) : null}
      <Popover
        id={id}
        open={open}
        anchorEl={popover.anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Stack spacing={2} p={2}>
          <Button
            variant="outlined"
            color="text"
            onClick={collapseAllNodes}
            endIcon={<VscCollapseAll size="18px" />}
          >
            {t('collapseAllNodes')}
          </Button>
          <FormControl>
            <h4>{t('nodeFiltersLabel')}</h4>
            <RadioGroup
              value={props.hideDrafts}
              name="radio-buttons-group"
              onChange={(e) => props.setHideDrafts(e.target.value)}
            >
              <FormControlLabel value={false} control={<Radio />} label={t('showAllNodes')} />
              <FormControlLabel value={true} control={<Radio />} label={t('showPublicationNodes')} />
            </RadioGroup>
          </FormControl>

          {props.disableRootChange ? null : (
            <FormControl fullWidth>
              <InputLabel id="root-select-label">{t('register')}</InputLabel>
              <Select
                labelId="root-select-label"
                id="root-select"
                label={t('register')}
                value={props.treeRoot}
                onChange={(event) => {
                  handlePopoverClose(event);
                  props.onRootChange(props.tabId, event.target.value, props.tabConfig);
                }}
                displayEmpty
                // style={{ paddingLeft: 8 }}
                // inputProps={{ "aria-label": "Without label" }}
              >
                {treeRoots
                  .sort((a, b) =>
                    t(a).localeCompare(t(b), undefined, {
                      numeric: true,
                      sensitivity: 'base',
                    })
                  )
                  .map((x) => (
                    <MenuItem key={'RootOptionMenuItem' + x} value={x}>
                      {t(x)}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        </Stack>
      </Popover>
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  const registryVersion =
    ownProps.registryVersion || localStorage.getItem("registryVersion");
  return {
    lng: "FIN",
    tree: state.trees[ownProps.reduxRoot]?.[registryVersion],
    productsMap: ownProps.productsMap || state.nodes.productsMap,
    nodesMap: ownProps.nodesMap || state.nodes.nodeMaps?.[registryVersion],
    wholeSalers: ownProps.wholeSalers || state.common.wholeSalers,
    reduxRegistryVersion: state.versions.registryVersion,
    registryVersion: registryVersion,
    versionChanges:
      ownProps.oldRegistryVersion &&
      state.versions.versionChanges?.[ownProps.treeRoot]?.[
        `${ownProps.oldRegistryVersion}-${registryVersion}`
      ],
  };
};

NodeTree.whyDidYouRender = {
  logOnDifferentValues: true,
};

export default connect(mapStateToProps, {
  SET_CHILDREN,
  SET_MULTIPLE_CHILDREN,
  SET_NODES,
  DELETE_NODE,
  UPDATE_NODE,
  SET_VERSION_CHANGES,
})(NodeTree);
